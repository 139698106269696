/* .welcome {
    top: 0;
    height: auto;
    width:auto;
    margin: 0px;
    padding: 60px;
    text-align: center;
    place-items: center;
    scroll-behavior: smooth;

} */

/* .welcome2{
background-image: url("/Users/johneyoute/Desktop/portfolioupdate/src/assets/boston.jpg");
background-opacity: 0.5;
background-color: black;
} */

h1 {
    line-height: 20px;
    color: white;
    text-align: center;
}

.text0 {
    font-size: 2em;
}

.text1 {
    font-size: 5em;
}

.text2 {
    font-size: 1em;
}

p {
    color: white;
    padding-top: 50px;
}

#butt {
    padding-top: 50px;
}

#butt button {
    background-color: #1e968e;
    color: black;
    border: 2px solid #10524e;
    margin: 10px;
    padding: 5px;
    border-radius: 10px;
    cursor: pointer;

}

#butt :hover {
    background-color: #ddd;
    color: white;

}

#moji {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40%;
}




.typing-demo {
    display: inline-flex;
    width: 400px;
    text-align: center;
    animation: typing 2s steps(15), blink .5s step-end infinite alternate;
    white-space: nowrap;
    overflow: hidden;
    border-right: 3px solid;
    font-family: monospace;
    font-size: 2em;
}

@keyframes typing {
    from {
        width: 0
    }
}

@keyframes blink {
    50% {
        border-color: transparent
    }
}

@media screen and (max-width: 500px) {

    .typing-demo {

        width: 290px;
        text-align: left;


    }

    #typing {

        font-size: 15px;
        justify-content: left;
        text-align: left;
    }


}

.scroll {
    height: 30px;
    margin-top: 150px;
    color: white;
    font-family: 'Courier New', Courier, monospace;


}

/* h3 {
    color: white;
    animation: animate 1.5s linear infinite;
} */


@keyframes animate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 0;
    }
}