.about {


    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 30px;
    font-weight: 200;
    font-style: oblique;
    text-align: center;
    justify-content: center;

    background-color: #10524e;
}

p {
    color: rgb(255, 255, 255);

}

#more {
    text-decoration: underline;
    text-decoration-color: rgb(255, 255, 255);
}