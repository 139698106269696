.cards {
    background-color: #e5e5f7;
    opacity: 0.8;
    background-image: radial-gradient(#10524e 0.5px, #e5e5f7 0.5px);
    background-size: 10px 10px;
    display: grid;

    grid-template-columns: repeat(1, 1fr);

    //grid-auto-rows: auto;

    grid-gap: 1rem;

}

.h {
    color: #10524e;
}

.Skills {
    color: #10524e;
    background-color: #10524e;
    opacity: 0.8;
    background-image: radial-gradient(#10524e 0.5px, #e5e5f7 0.5px);
    background-size: 10px 10px;
}


@media screen and (max-width: 500px) {
    .cards {
        grid-template-columns: repeat(1, 1fr);

    }
}

@media screen and (min-width: 800px) {
    .cards {
        grid-template-columns: repeat(3, 1fr);

    }
}





.innercard {
    margin: 10px;
}