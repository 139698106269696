#scroller {
    overflow-y: scroll;
  }
  
  #scroller::-webkit-scrollbar {
    width: 0;
    height: 0;
    background-color: transparent;
  }
  
  #scroller::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.5);
  }